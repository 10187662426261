<template>
  <div class="view-home">
    <div class="container">
      <div class="row about-page">
        <div class="col-md-12">
          <div class="breadcrumb px-0">
            <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
            <router-link to="/about" class="active">{{ $t('about-page.title') }}</router-link>
          </div>
          <div class="row">
            <div class="col-12">
              <h1 class="title about-title mb-3">{{ $t('about-page.title') }}</h1>
            </div>
            <div class="col-lg-10 col-md-12 pt-5 mx-auto">
              <div class="about-content">

                <!--                <div class="card-main col-12 col-md-12 float-right my-5" style="border: 1px solid red">-->
                <!--                  <div class="icon"></div>-->
                <!--                  <h2 class="text-center">{{ $t('about-page.project') }}</h2>-->
                <!--                  <p>{{ $t('about-page.project-desc') }}</p>-->
                <!--                  <p>{{ $t('about-page.project-desc-2') }}</p>-->
                <!--                </div>-->
                <div class="about-olympiad-desc">
                  {{ $t('about_olympiad_desc') }}
                </div>
                <div class="accordion-about" id="accordion">
                  <div class="card card-about" key="card-1">
                    <div class="card-header" @click="accordion = 'collapse' + 'card-1'" :id="'heading' + 'card-1'">
                      <h5 class="mb-0">
                        <button class="btn btn-link" :class="{'active': accordion == 'collapse' + 'card-1'}"
                                data-toggle="collapse" :data-target="'#collapse' + 'card-1'" aria-expanded="false"
                                :aria-controls="'collapse' + 'card-1'">
                          {{ $t('about-page.project-goals') }}
                        </button>
                      </h5>
                    </div>

                    <div :id="'collapse' + 'card-1'" :class="{'show': accordion == 'collapse' + 'card-1'}"
                         class="collapse" :aria-labelledby="'heading' + 'card-1'" data-parent="#accordion">
                      <div class="card-body pt-0">
                        <hr class="mt-0">
                        <div class="card about-1">
                          <p>{{ $t('about-page.project-goals-desc') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card card-about" key="card-2">
                    <div class="card-header" @click="accordion = 'collapse' + 'card-2'" :id="'heading' + 'card-2'">
                      <h5 class="mb-0">
                        <button class="btn btn-link" :class="{'active': accordion == 'collapse' + 'card-2'}"
                                data-toggle="collapse" :data-target="'#collapse' + 'card-2'" aria-expanded="false"
                                :aria-controls="'collapse' + 'card-2'">
                          {{ $t('about-page.project-participation') }}
                        </button>
                      </h5>
                    </div>

                    <div :id="'collapse' + 'card-2'" :class="{'show': accordion == 'collapse' + 'card-2'}"
                         class="collapse" :aria-labelledby="'heading' + 'card-2'" data-parent="#accordion">
                      <div class="card-body pt-0">
                        <hr class="mt-0">
                        <div class="card about-2">
                          <p>{{ $t('about-page.project-participation-desc') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card card-about" key="card-3">
                    <div class="card-header" @click="accordion = 'collapse' + 'card-3'" :id="'heading' + 'card-3'">
                      <h5 class="mb-0">
                        <button class="btn btn-link" :class="{'active': accordion == 'collapse' + 'card-3'}"
                                data-toggle="collapse" :data-target="'#collapse' + 'card-3'" aria-expanded="false"
                                :aria-controls="'collapse' + 'card-3'">
                          {{ $t('about-page.project-results') }}
                        </button>
                      </h5>
                    </div>

                    <div :id="'collapse' + 'card-3'" :class="{'show': accordion == 'collapse' + 'card-3'}"
                         class="collapse" :aria-labelledby="'heading' + 'card-3'" data-parent="#accordion">
                      <div class="card-body pt-0">
                        <hr class="mt-0">
                        <div class="card about-3">

                          <p>{{ $t('about-page.project-results-desc-1') }}</p>
                          <p>{{ $t('about-page.project-results-desc-2') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card card-about" key="card-4">
                    <div class="card-header" @click="accordion = 'collapse' + 'card-4'" :id="'heading' + 'card-4'">
                      <h5 class="mb-0">
                        <button class="btn btn-link" :class="{'active': accordion == 'collapse' + 'card-4'}"
                                data-toggle="collapse" :data-target="'#collapse' + 'card-4'" aria-expanded="false"
                                :aria-controls="'collapse' + 'card-4'">
                          {{ $t('about-page.project-selection') }}
                        </button>
                      </h5>
                    </div>

                    <div :id="'collapse' + 'card-4'" :class="{'show': accordion == 'collapse' + 'card-4'}"
                         class="collapse" :aria-labelledby="'heading' + 'card-4'" data-parent="#accordion">
                      <div class="card-body pt-0">
                        <hr class="mt-0">
                        <div class="card about-4">
                          <p>{{ $t('about-page.project-selection-desc') }}<br>
                            <span class="text-primary">{{
                                $t('about-page.project-selection-step-1')
                              }}</span><br>{{ $t('about-page.project-selection-step-1-desc') }}<br>
                            <span class="text-primary">{{
                                $t('about-page.project-selection-step-2')
                              }}</span><br>
                            <span v-html="$t('about-page.project-selection-step-2-desc')"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="about_slider_desc">
                  <carousel
                      style="width: 100%;"
                      :per-page="1"
                      navigationEnabled='true'
                      navigation-next-label=""
                      navigation-prev-label=""
                      paginationActiveColor="#2091F9"
                      paginationColor="rgba(32, 145, 249, 0.2)"
                      :navigateTo="manualNavigation"
                  >
                    <slide class="about_slide">
                      <div class="about_slide_video_block">

                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/ZqKqhejHhJI"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author-6') }}
                        </div>
                        <div class="about_slide_desc_text" v-html="$t('for-members.review-6')">
                        </div>
                        <a class="about_slide_desc_more" href="https://www.youtube.com/watch?v=ZqKqhejHhJI "
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>
                    <slide class="about_slide">
                      <div class="about_slide_video_block">
                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/S1gzPcEoGg4"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author') }}
                        </div>
                        <div class="about_slide_desc_text">
                          {{ $t('for-members.review') }}
                        </div>
                        <a class="about_slide_desc_more" href="https://www.youtube.com/watch?v=S1gzPcEoGg4"
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>
                    <slide class="about_slide">
                      <div class="about_slide_video_block">
                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/eW1SM-jkens"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author-2') }}
                        </div>
                        <div class="about_slide_desc_text">
                          {{ $t('for-members.review-2') }}
                        </div>
                        <a class="about_slide_desc_more" href="https://www.youtube.com/watch?v=eW1SM-jkens"
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>
                    <slide class="about_slide">
                      <div class="about_slide_video_block">
                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/EdiCRgJFv94"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author-3') }}
                        </div>
                        <div class="about_slide_desc_text">
                          {{ $t('for-members.review-3') }}
                        </div>
                        <a class="about_slide_desc_more" href="https://www.youtube.com/watch?v=EdiCRgJFv94"
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>
                    <slide class="about_slide">
                      <div class="about_slide_video_block">
                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/nuS250SaWbs"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author-4') }}
                        </div>
                        <div class="about_slide_desc_text">
                          {{ $t('for-members.review-4') }}
                        </div>
                        <a class="about_slide_desc_more"
                           href="https://www.youtube.com/watch?v=nuS250SaWbs&list=PLrKvqKTbQFhPbQZh3MB1F2sV-RLP1xxlW&index=30"
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>
                    <slide class="about_slide">
                      <div class="about_slide_video_block">
                        <iframe class="about_slide_video" src="https://www.youtube.com/embed/xqO9jtmyb2w"
                                frameborder="1"></iframe>
                      </div>
                      <div class="about_slide_desc">
                        <div class="about_slide_desc_title">
                          {{ $t('for-members.review-author-5') }}
                        </div>
                        <div class="about_slide_desc_text">
                          {{ $t('for-members.review-5') }}
                        </div>
                        <a class="about_slide_desc_more" href="https://www.youtube.com/watch?v=xqO9jtmyb2w"
                           target="_blank">
                          {{ $t('schools-list.more-button') }}
                        </a>
                      </div>
                    </slide>

                  </carousel>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container about-page-section">
      <div class="row py-5">
        <div class="col-lg-10 col-md-12 mx-auto files_to_download">
          <h1 class="title">{{ $t('about-page.download-files-title') }}</h1>
          <div class="dropdown">
            <a class="btn btn-primary" v-if="$i18n.locale === 'ru'" type="button" href="/files/ru/Правила_МынБалаРус_24-04.pdf" download>
              {{ $t('about-page.olympiad-title') }}
            </a>
            <a class="btn btn-primary" v-if="$i18n.locale === 'kz'" type="button" href="/files/kz/Ереже_МынБала_КАЗ_24-04.pdf" download>
              {{ $t('about-page.olympiad-title') }}
            </a>
          </div>
          <div class="dropdown">
            <a download href="/files/53_приказ_Мын_бала_2023.pdf" class="btn btn-primary" type="button">
              {{ $t('about-page.school-enrollment') }}
            </a>
          </div>

        </div>
        <div class="col-lg-10 col-md-12 mx-auto">
          <div class="connect-with-coordinator row">
            <div class="coordinator_block_left">
              <div class="clearfix">
                <div class="card">
                  <i></i>
                  <div class="coordinator_name">{{ $t('about-page.coordinator-name') }}</div>
                  <p>{{ $t('about-page.coordinator') }}</p>
                  <p>{{ $t('about-page.coordinator-email') }} <br><strong>{{
                      $t('about-page.coordinator-email-desc')
                    }}</strong></p>
                  <p class="mb_tel">{{
                      $t('about-page.coordinator-tel')
                    }}<strong>{{ $t('about-page.coordinator-tel-desc') }}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="coordinator_block_right">
              <div class="form-card">
                <form class="coordinator-form">
                  <div class="card-header">
                    <h5>{{ $t('about-page.contact-coordinator') }}</h5>
                  </div>
                  <div class="card-body clearfix">
                    <el-input
                        :class="{'el-input--danger': $v.name.$error}"
                        type="textarea"
                        :rows="5"
                        :placeholder="$t('about-page.your-question')"
                        v-model="$v.question.$model"/>
                    <el-input
                        :class="{'el-input--danger': $v.name.$error}"
                        :placeholder="$t('about-page.your-name')"
                        v-model.trim="$v.name.$model"
                        clearable/>
                    <el-input
                        :class="{'el-input--danger': $v.phone_number.$error}"
                        :placeholder="$t('about-page.phone-number')"
                        v-model.trim="$v.phone_number.$model"
                        v-mask="'+7 (###) ### ##-##'"
                        clearable/>
                    <el-button @click="sendData" class="btn btn-primary btn-block" :loading="loading">
                      {{ $t('about-page.send-question-btn') }}
                    </el-button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 col-md-12 mx-auto sponsors_pl">
          <div class="sponsors row sponsors_center_alignment">
            <div class="col-lg-4 col-md-12 sponsors-mb">
              <div class="card">
                <i v-bind:class="{'sponsor-fnn-logo': this.$i18n.locale === 'ru', 'sponsor-fnn-logo-kz': this.$i18n.locale === 'kz'}"></i>
                <p>{{ $t('about-page.olympiad-founder') }}</p>
                <strong class="text-primary">{{ $t('about-page.fnn') }}</strong>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 sponsors-mb">
              <div class="card">
                <i class="sponsor-elumiti-logo"></i>
                <p>{{ $t('about-page.olympiad-organizer') }}</p>
                <strong class="text-warning">{{ $t('about-page.elbasy-academy') }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {required, minLength} from "vuelidate/lib/validators";
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VueMask from 'v-mask'
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'About',
  components: {
    VueperSlides,
    VueperSlide,
    Carousel,
    Slide,
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      manualNavigation: 0,
      tab: 'comment1',
      accordion: 'collapse0',
      question: '',
      name: '',
      phone_number: '',
      loading: false,
      olympiadRulesDownload: "/files/Правила_Мың_бала_2024.pdf",
    }
  },
  validations: {
    question: {
      required
    },
    name: {
      required
    },
    phone_number: {
      required,
      minLength: minLength(18)
    }
  },
  methods: {
    async sendData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('about-page.form-empty-error'),
          type: 'warning'
        });
      } else {
        try {
          this.loading = true
          const response = await this.$http.post(window.API_ROOT + '/api/send-feedback', {
            name: this.name,
            phone: this.phone_number,
            question: this.question
          })
          if (response.body.status == 'success') {
            this.name = '';
            this.phone_number = '';
            this.question = '';
            Vue.toastr({
              message: 'Success',
              description: this.$t('about-page.email-sended'),
              type: 'success'
            });
            this.loading = false
          } else {
            Vue.toastr({
              message: this.$t('error'),
              description: res.body.status,
              type: 'error'
            });
            this.loading = false
          }
        } catch (error) {
          Vue.toastr({
            message: this.$t('error'),
            description: error,
            type: 'error'
          });
        }
      }
    }
  }
}
</script>
<style>
.winner-image {
  width: 172px;
  height: 172px;
  margin-bottom: 15px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.carousel-item-wrap {
  display: flex;
  flex-direction: column;
}

.carousel-item-wrap img {
  display: block;
  margin: auto !important;
}

.carousel-item-wrap div {
  display: block;
  margin: auto !important;
}
</style>
<style scoped>
.reviews .users-wrap a.active div {
  max-width: 100%;
}

.card p {
  line-height: 1.5;
}

.about-page .about-content .card {
  background-image: unset !important;
  padding: 0 1.25rem 1.25rem;

}

#accordion .card .card {
  box-shadow: unset !important;
}
</style>
<style lang="scss">
.reviews {
  padding: 0 !important;
}

.card-body {
  padding: 0;
}

.about-content {
  display: flex;
  flex-direction: column;
}

.card-about {
  padding: 0 !important;
}

.about-page-section {
  .form-card {
    .el-textarea__inner,
    .el-input__inner {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
      border-radius: 4px;
      border: none;
      margin-bottom: 20px;
      padding: 1.5rem 0.75rem;

      &::placeholder {
        color: #6c757d;
      }
    }
  }
}

.el-input {
  &--danger {
    .el-textarea__inner,
    .el-input__inner {
      box-shadow: 0px 4px 4px rgb(255 51 51 / 10%), 0px 4px 16px rgb(255 51 51 / 8%) !important;
    }
  }
}
</style>
<style>
.form-group {
  text-align: left;
}

.signin-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.slider-block {
  padding: 40px 0 20px;
  width: 730px;
  margin: 0 auto;
}

.vueperslides__track-inner {
  align-items: center;
}

.vueperslides__parallax-wrapper {
  height: 320px !important;
}

.vueperslide {
  height: 200px !important;
  background-size: 134px 134px;
  background-repeat: no-repeat;
  background-size: cover;
}


.vueperslides__arrow {
  color: #303030;
  background-color: #ececec;
  padding: 11px 4px;
  border-radius: 2px;
}

.vueperslides__arrow > img {
  height: 12px;
}

.content .school-desc {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 40px;
  font-family: 'Roboto', sans-serif;
  color: #303030;
}

.btn-back {
  font-size: 13px;
  background-color: #e0e0e07a;
  padding: 8px 18px;
  color: #8b8b8b;
}

.school-name-block {
  border-bottom: 1px solid #e0e0e0;
  width: 470px;
}

.school-name,
.school-name-mob {
  color: #18a0fb;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #18a0fb;
  width: fit-content;
}

.info-block {
  display: flex;
  align-items: center;
}

.info-block img {
  width: 14px;
  height: 14px;
}

.info-title-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.info-text {
  color: #747474;
  font-size: 10px;
  line-height: 5px;
}

.add-info-text {
  font-size: 18px;
  font-weight: 500;
}

.desc-left-part {
  width: calc(100% - 360px);
}

.desc-right-part {
  width: 50%;
  text-align: center;
}

.desc-right-part img {
  width: auto;
  max-width: 360px;
  height: 360px;
  object-fit: cover;
}

.school-desc-title {
  font-size: 30px;
  line-height: 35px;
}

.school-desc-text {
  font-size: 18px;
  line-height: 21px;
}

.school-desc-block {
  display: flex;
  flex-direction: row;
}

.school-name-mob {
  display: none;
  margin: 0 auto;
  margin-bottom: 30px;
}

.info-row {
  display: flex;
  flex-direction: row;
}

.btns-block,
.add-info-block {
  display: flex;
  flex-direction: row;
}

.btns-block .btn:first-child {
  margin-right: 32px;
}

.social-network-block {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  width: fit-content;
}

.social-network-block p {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

.modal #dialog span {
  white-space: break-spaces;
}

#application-wrapper button.close {
  right: -1rem !important;
  left: auto !important;
}

#withdraw-wrapper button.close {
  right: 1rem !important;
  left: auto !important;
}

#withdraw-modal .modal-dialog {
  top: 25%;
}

.no-places-dialog {
  display: inline-block;
  position: relative;
  width: 100%;
}

.modal #no-places-dialog button.close {
  border-radius: 44px;
  width: 44px;
  height: 44px;
  background-color: #b5b5b5;
  background-image: url(/img/close.be409dec.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  opacity: 1;
  transition: all 0.2s ease;
}

.modal #no-places-dialog span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal #no-places-dialog span {
  white-space: break-spaces;
}

.modal #no-places-dialog div {
  position: relative;
  z-index: 1;
}

.modal #no-places-dialog button {
  padding: 10px 20px;
}

.no-places-modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 20px;
}

.no-places-modal-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .school-desc-block {
    flex-direction: column-reverse;
    align-items: center;
  }

  .school-name-block {
    display: none;
  }

  .school-name-mob {
    display: block;
  }

  .desc-right-part {
    width: 100%;
    margin-bottom: 30px;
  }

  .desc-right-part > img {
    display: none;
  }

  .desc-left-part {
    width: 100%;
  }

  @media screen and (max-width: 1200px) and (min-width: 690px) {
    .slider-block {
      width: 530px;
    }

    .vueperslide {
      background-size: 90px 90px;
    }

    .vueperslide--active {
      background-size: contain;
    }
  }
  @media screen and (max-width: 992px) {
    .el-checkbox {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }
  @media screen and (max-width: 690px) {
    .slider-block {
      width: 100%;
      padding: 0 50px;
    }
  }
  @media screen and (max-width: 550px) {
    .desc-right-part img {
      width: auto;
      max-width: 250px;
      height: auto;
    }
  }
  @media screen and (max-width: 500px) {
    .content .school-desc {
      padding: 20px;
    }

    .info-row {
      flex-direction: column;
    }

    .add-info-block {
      flex-direction: column;
      padding: 30px 0;
    }

    .add-info-block .add-info-text:first-child {
      padding-bottom: 20px;
    }

    .btns-block .btn:first-child {
      margin-right: 16px;
    }
  }
}
</style>
